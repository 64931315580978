import React from "react"
import { Link } from "gatsby"
import "./terms.css"

const css = (active, type) =>
  `terms-header-item ${active == type ? "terms-active" : ""}`

const Faq = ({ active, children }) => (
  <div>
    <div className="terms-header">
      <Link stripHash to="/faq#models" style={{ textDecoration: "none" }}>
        <div className={css(active, "models")}>MODELS</div>
      </Link>
      <Link stripHash to="/faq#brands" style={{ textDecoration: "none" }}>
        <div className={css(active, "brands")}>BRANDS</div>
      </Link>
    </div>
    <div className="terms-content">{children}</div>
    <br/>
  </div>
)

export default Faq
