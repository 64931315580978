import React from "react"
import {default as FaqTabs} from "../components/faq"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./privacy_policy.css"

const Faq = () => {

  let tab =  typeof window !== "undefined"
    ? window.location.hash.replace('#', '')
    : 'brands';

  tab = ['brands', 'models'].indexOf(tab) !== -1
    ? tab
    : 'brands';

  return (

    <Layout page="terms">
      <SEO title="FAQ - Brands" description="Our terms of use for the brands we work with." />
      <div className="flex-grid">
        <div className="col" />
        <div style={{ flex: "0 1 994px", marginTop: "50px" }}>
          <FaqTabs active={tab}>
            <div
              className="privacy-policy-title"
              style={{ marginTop: "-20px" }}
            >
              FREQUENTLY ASKED QUESTIONS
            </div>

            {
              tab !== 'brands' ?
              <div className="privacy-policy-text">
                <p><strong>What is Neon Coat?</strong></p>
                <p>Neon Coat is a professional model&rsquo;s passport to everything. The app provides professional models access to unique experiences at world-class businesses, products from premium brands, and quality modeling jobs. Neon Coat empowers models to curate their own social and professional lives.</p>
                <p><strong>Who can be a Neon Coat member?</strong></p>
                <p>Neon Coat is exclusively for professional models in the industry who are actively being cast in high-quality professional modeling work. Having a lot of followers on social media does not on its own qualify you to be a Neon Coat member.</p>
                <p><strong>Where are Neon Coat in-person offers available?</strong></p>
                <p>Neon Coat currently operates in New York, Miami and Los Angeles, with plans to expand to other fashion capitals soon!</p>
                <p>If you couldn't find the answer to your question above, please contact us at <a href="mailto:info@neoncoat.com">info@neoncoat.com</a></p>
              </div>
                :
                <div className="privacy-policy-text">
                  <p><strong>What is Neon Coat?</strong></p>
                  <p>Neon Coat is a technology platform that directly connects brands and businesses to the world&rsquo;s largest community of professional models, with no third party necessary.</p>
                  <p><strong>What does Neon Coat do for a brand or business?</strong></p>
                  <p>Neon Coat can connect your brand or business with professional models for a variety of different services in the physical and digital worlds, ranging from in-person appearances to posts on social media to casting for modeling jobs. These services elevate your brand or business, expose you to new potential customers, and ultimately boost revenue.&nbsp;</p>
                  <p><strong>What kind of brand or business can Neon Coat help?</strong></p>
                  <p>Neon Coat has helped brands as small as a 1-person startup and as large as a multinational beauty brand, and everything in between. Whatever product you sell, or whatever service you provide, professional models are timeless ambassadors for your brand!</p>
                  <p><strong>Who can be a Neon Coat model member?</strong></p>
                  <p>Only elite professional models who are currently working in the industry can be Neon Coat members. Our diverse model members represent all different backgrounds, sizes, and genders.</p>
                  <p><strong>Where is Neon Coat available for brick-and-mortar businesses?</strong></p>
                  <p>Neon Coat currently operates in New York, Miami and Los Angeles, with plans to expand to other fashion capitals soon!</p>
                  <p>If you couldn't find the answer to your question above, please contact us at <a href="mailto:partnerships@neoncoat.com">partnerships@neoncoat.com</a></p>
                </div>
            }
          </FaqTabs>
        </div>
        <div className="col" />
      </div>
    </Layout>
  )
}

export default Faq;
